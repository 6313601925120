<template>
  <q-card :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-md'">
    <div class="row no-wrap items-center justify-between text-h6 text-center">
      <div class="q-pr-sm">{{ pond.name }}</div>
      <div class="q-pb-sm col-auto">
        <q-btn
          unelevated
          label="Fish In / Out"
          color="primary"
          :padding="$q.screen.xs ? '2px 6px' : '2px 8px'"
          size="md"
          @click="$emit('goToFishInOut', pond)"
        />
      </div>
    </div>
    <q-separator />

    <div class="col row justify-between q-py-xs">
      <div>Head</div>
      <div>{{ PondInfo.head.toLocaleString() }}</div>
    </div>
    <q-separator />

    <div class="col row justify-between q-py-xs">
      <div>Estimated Weight</div>
      <div>{{ PondInfo.weight.toLocaleString() }}</div>
    </div>
    <q-separator />

    <div
      v-for="group of ActiveGroups"
      :key="group.stocking_id"
      class="col-12 q-pb-sm q-pl-md"
    >
      <div class="text-subtitle2 q-py-xs">
        {{ group.name }}
      </div>

      <div class="q-pl-md">
        <div class="row justify-between">
          <div>Size</div>
          <div>{{ group.size.toLocaleString() }}</div>
        </div>
        <q-separator />
      </div>

      <div class="q-pl-md">
        <div class="row justify-between">
          <div>Head</div>
          <div>{{ group.head.toLocaleString() }}</div>
        </div>
        <q-separator />
      </div>

      <div class="q-pl-md">
        <div class="row justify-between">
          <div>Estimated Weight</div>
          <div>{{ group.weight.toLocaleString() }}</div>
        </div>
        <q-separator />
      </div>
    </div>
  </q-card>
</template>

<script>
import store from '@/store';
import { decoder, numberWithCommas } from '@/lib/helpers';
import { date } from 'quasar';

export default {
  name: 'Pond-Overview',
  props: {
    pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      numberWithCommas
    };
  },
  methods: {
    getNumGroups(pond) {
      return numGroups;
    }
  },
  computed: {
    ActiveGroups() {
      return this.pond.growth.FishGroups.filter(
        (group) => group.status === 'active'
      );
    },
    PondInfo() {
      const info = {
        numGroups: 0,
        head: 0,
        weight: 0
      };

      for (const group of this.pond.growth.FishGroups) {
        if (group.status !== 'active') {
          continue;
        }

        info.numGroups++;
        info.head += group.head;
        info.weight += group.weight;
      }

      return info;
    }
  }
};
</script>

<style></style>
