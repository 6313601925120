<template>
  <q-card style="width: 450px">
    <div class="text-h6 text-center q-py-sm">
      {{ Pond.name }}
    </div>
    <q-separator />

    <div class="scroll" style="max-height: calc(100vh - 80px)">
      <q-form @submit="submitFlavor()">
        <div class="q-px-lg q-pt-sm q-pb-sm">
          <div class="q-pb-sm">
            <q-input
              outlined
              dense
              label="Date"
              v-model="flavor.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="flavor.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="flavor.proc_id"
              label="Select Processor"
              dense
              outlined
              :options="ProcessorOptions"
              emit-value
              map-options
              :disable="action === 'edit'"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Processor']"
            />
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="flavor.flavor"
              label="Select Flavor"
              dense
              outlined
              :options="FlavorOptions"
              emit-value
              map-options
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Flavor']"
            />
          </div>

          <div>
            <q-input
              outlined
              dense
              autogrow
              v-model="flavor.memo"
              label="Note"
            />
          </div>
        </div>

        <div class="q-pt-xs q-px-lg">
          <div style="padding: 4px 12px 0 12px">
            <q-slider
              dense
              v-model="flavor.severity"
              :min="0"
              :max="5"
              markers
              :step="1"
              snap
              color="accent"
              track-size="10px"
              thumb-size="30px"
            />
          </div>
          <div class="row justify-center text-subtitle1 q-pb-xs">
            Severity - {{ flavor.severity }}
          </div>
        </div>
        <q-separator />

        <div class="row q-py-lg q-px-lg">
          <div class="col q-pr-sm">
            <q-btn
              rounded
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              v-close-popup
            />
          </div>
          <div v-if="action === 'update'" class="col q-px-sm">
            <q-btn
              rounded
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              padding="10px md"
              class="full-width"
              @click="deleteFlavor"
            />
          </div>
          <div class="col q-pl-sm">
            <q-btn
              rounded
              label="Submit"
              color="secondary"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              type="submit"
            />
          </div>
        </div>
      </q-form>
    </div>
  </q-card>
</template>

<script>
import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultFlavor() {
  return {
    id: null,
    user_id: store.state.user.id,
    proc_id: null,
    flavor: null,
    severity: 0,
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'FlavorPondRow',
  props: {
    Action: {
      type: String,
      required: true
    },
    Pond: {
      type: Object,
      required: true
    },
    Flavor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      decoder,
      isDateValid,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      qtyErr: false,
      flavor: defaultFlavor()
    };
  },
  mounted() {
    this.flavor = {
      ...defaultFlavor(),
      ...cloneObj(this.Flavor)
    };

    this.dateCopy = this.flavor.date;
    this.flavor.date = date.formatDate(
      new Date(this.flavor.date * 1000),
      'MM/DD/YYYY'
    );
    this.dateCopyStr = this.flavor.date;

    this.action = this.Action;
  },
  methods: {
    deleteFlavor() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this flavor?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitFlavor();
        });
    },
    submitFlavor() {
      const flavor = cloneObj(this.flavor);

      const dateStr = this.flavor.date;
      flavor.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        flavor.date = this.dateCopy;
      }

      if (this.action === 'create') {
        flavor.id = 0;
      }

      const flavorObj = this.FlavorOptions.find(
        (x) => x.value === flavor.flavor
      );

      const procObj = this.ProcessorOptions.find(
        (x) => x.value === flavor.proc_id
      );

      flavor.guid = getUID();
      flavor.user_id = store.state.user.user_id;
      flavor.flavor_name = flavorObj?.name ?? '';
      flavor.processor = procObj.name;
      flavor.storeInfo.action = this.action + 'FlavorPond';
      flavor.storeInfo.category = 'flavorPond';
      flavor.storeInfo.pondId = this.Pond.id;

      guidMatchHelper(flavor, this.action);

      store.dispatch('publish', flavor);

      this.$q.notify({
        message: `Entry ${
          this.action === 'create'
            ? 'Added'
            : this.action === 'update'
            ? 'Updated'
            : 'Deleted'
        }`,
        icon: 'check',
        color: this.action === 'delete' ? 'negative' : 'primary'
      });

      this.$emit('close');
    }
  },
  computed: {
    CurrentFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial flavor, return null
      if (this.pond.flavorHistory.length === 0) {
        return null;
      }

      // find the most recent flavor
      const currFlavor = this.pond.flavorHistory.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currFlavorDate = date.formatDate(
        currFlavor.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currFlavorDate ? currFlavor : null;
    },
    LastTenEntries() {
      return this.pond.flavorHistory.slice(0, 10);
    },
    LastFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastFlavor = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.pond.flavorHistory.length; i++) {
        const flavor = this.pond.flavorHistory[i];

        const flavorDate = date.formatDate(
          new Date(flavor.date * 1000),
          'YYYY-MM-DD'
        );

        if (flavorDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastFlavor = cloneObj(flavor);

          break;
        }
      }

      return lastFlavor;
    },
    FlavorAmount() {
      let amount = parseFloat(this.flavor.flavor);

      const decimalCount = this.flavor.flavor.split('.').length - 1;

      if (this.treatment.flavor.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.treatment.flavor === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    },
    FlavorOptions() {
      const flavors = [];
      for (const flavor of store.state.farm.flavors) {
        flavors.push({
          value: flavor.id,
          name: flavor.name,
          label: flavor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = flavors.some((x) => x.value === this.flavor.flavor);

        if (!hasId) {
          flavors.push({
            value: this.flavor.flavor,
            name: this.flavor.flavor_name,
            label: this.flavor.flavor_name
          });
        }
      }

      return flavors;
    },
    ProcessorOptions() {
      const processors = [];
      for (const processor of store.state.farm.processors) {
        processors.push({
          value: processor.id,
          name: processor.name,
          label: processor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = processors.some((x) => x.value === this.flavor.proc_id);

        if (!hasId) {
          processors.push({
            value: this.flavor.proc_id,
            name: this.flavor.processor,
            label: this.flavor.processor
          });
        }
      }

      return processors;
    }
  }
};
</script>

<style></style>
